import React, { Fragment, useEffect, useState } from 'react'

import { Disclosure } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { Link,useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import {signOut} from '../action/login'
const NavBar = ({signOut}) => {
    const location = useLocation();
    let navigation = []
    localStorage.getItem('thirdparty') == 'true' ?
    
    navigation = [
        // { name: 'DASHBOARD', to: '/', current: location.pathname == '/' ? true : false },
        { name: 'PARCELS', to: '/parcels', current: (location.pathname.slice('-')).includes('/parcels')  ? true : false },
        { name: 'COLLECT AMOUNT', to: '/collect-amount-tp', current: location.pathname === '/collect-amount-tp'  ? true : false },
        { name: 'REPORT', to: '/report-tp', current: location.pathname === '/report-tp'  ? true : false  },
      ]
    :
    navigation = [
        // { name: 'DASHBOARD', to: '/', current: location.pathname == '/' ? true : false },
        { name: 'PARCELS', to: '/parcels', current: (location.pathname.slice('-')).includes('/parcels')  ? true : false },
        { name: 'RIDER', to: '/riders', current: location.pathname === '/riders'  ? true : false },
        { name: 'COLLECT AMOUNT', to: '/collect-amount', current: location.pathname === '/collect-amount'  ? true : false },
        { name: 'TRANSACTIONS', to: '/transactions', current: location.pathname === '/transactions'  ? true : false  },
        { name: 'REPORT', to: '/reports', current: location.pathname === '/reports'  ? true : false  },
      ]
    
      const classNames = (...classes) =>{
        return classes.filter(Boolean).join(' ')
      }
    return ( 
        <div className='sticky top-0 z-50'>
            <Disclosure as="nav" className="bg-primary">
      {({ open }) => (
        <>
            {/* ========== Desktop Menu ========= */}
            <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                <div className="relative flex items-center justify-between h-16">
                    {/* ==========Mobile menu button=========== */}
                    <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                        
                        <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                            <XIcon className="block h-6 w-6 text-white" aria-hidden="true" />
                        ) : (
                            <MenuIcon className="block h-6 w-6 text-white" aria-hidden="true" />
                        )}
                        </Disclosure.Button>
                    </div>
                    {/* =========== Logo and menu item ========== */}
                    <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                        <div className="flex-shrink-0 flex items-center">
                        <img
                            className="block lg:hidden h-8 w-auto"
                            src='./images/w-logo.png'
                            alt="Workflow"
                        />
                        <img
                            className="hidden lg:block h-8 w-auto"
                            src='./images/w-logo.png'
                            alt="Workflow"
                        />
                        </div>

                        <div className="hidden sm:block sm:ml-6">
                        <div className="flex space-x-4">
                            {navigation.map((item) => (
                            <Link
                                key={item.name}
                                to={item.to}
                                className={classNames(
                                item.current ? 'bg-secondary text-white' : 'text-white hover:bg-secondary hover:text-white',
                                'px-3 py-2 rounded-md text-sm font-medium '
                                )}
                                aria-current={item.current ? 'page' : undefined}
                            >
                                {item.name}
                            </Link>
                            ))}
                        </div>
                        </div>

                    </div>
                    {/* ========= Right Bars ====== */}
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                        
                        <span className='text-white m-2 font-semibold'>{localStorage.getItem('warehouse_name') ? localStorage.getItem('warehouse_name') : 'Warehouse'}</span>
                        <button
                        type="button"
                        className="bg-secondary p-2 rounded-sm text-white  focus:outline-none  focus:ring-white"
                        onClick={() => signOut()}
                        >
                        LOGOUT
                        </button>

                    </div>
                
                </div>
            </div>
            {/* ========== Mobile Menu ========= */}
            <Disclosure.Panel className="sm:hidden">
                <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map((item) => (
                    <Link
                    key={item.name}
                    to={item.to}
                    className={classNames(
                        item.current ? 'bg-secondary text-white' : 'text-white hover:bg-secondary hover:text-white',
                        'block px-3 py-2 rounded-md text-base font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                    >
                    {item.name}
                    </Link>
                ))}
                </div>
            </Disclosure.Panel>
        </>
      )}
            </Disclosure>
    
        </div>
     );
}
 
export default connect(null,{signOut})(NavBar);