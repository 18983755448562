
import axios from 'axios';

export default (token = false) => {
    
    return axios.create({
        // baseURL: 'https://devtest.bahonexpress.com/warehouse/',
        baseURL: 'https://api.jatayat3pl.com/warehouse/',
        // baseURL: 'https://api.bahonexpress.com/warehouse/',
        // baseURL: 'http://api.bahon.co/warehouse/',
        headers: {
            // 'authorization': `Bearer 1212121212`,
             'content-type': 'application/json',
             
            'authorization': `Bearer ${token}`
        }
    })
}