import React, { Component } from 'react'

import { Button, Col, Empty, Input, Row, Space, Table, Tag } from 'antd';
import { connect } from 'react-redux';
import {fetchPending,confirmParcel} from '../../../../action/parcel'
import { CheckOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from "react-highlight-words";
import Header from '../../../../components/header';
import '../../index.css'
import {FaCheck} from 'react-icons/fa'
class PendingParcels extends Component {
  state = { 
    searchText: '',
    searchedColumn: '',
    data: [],
    coverageArea: [],
    found: 0,
   } 
    componentDidMount = () => {
        this.getData()
        // this.handleCity()
    };
    getData = async () => {
        const res = await this.props.fetchPending()
        this.setState({data: res?.list, coverageArea: res?.area, found: res?.list?.length})
    }

    getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div
          style={{
            padding: 8,
          }}
        >
          <Input 
            ref={node => {
                this.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={ e => {setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
              this.setState({
              searchText: e.target.value,
              searchedColumn: dataIndex,
              });}}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: 'block',
            }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{
            color: filtered ? '#1890ff' : undefined,
          }}
        />
      ),
      onFilter: (value, record) =>
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => this.searchInput.select(), 100);
        }
      },
      render: (text) =>
        this.state.searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    });
    
    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
        searchText: selectedKeys[0],
        searchedColumn: dataIndex,
        });
    };

    createData = (key,store_name,store_info,to,city,area,customer_info,charge_info,order_time,type,action) => {
        return {key,store_name,store_info,to,city,area,customer_info,charge_info, order_time,type,action};
    };

    makeLocalTime = (date) => {
        let mainDate = date.split('T')[0];
        let hours = date.split('T')[1].split(':')[0];
        let minutes = date.split('T')[1].split(':')[1];
        var am = true;
        if (hours > 12) {
            am = false;
            hours -= 12
        } else if (hours == 12) {
            am = false;
        } else if (hours == 0) {
            hours = 12
        }
        return mainDate + " , " + hours+":"+minutes + " " + (am ? "a.m" : "p.m")
    }

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    handleSubmit = async (merchant_mobile_number,order_id,recipient_mobile_number,shop_id) => {
        const res = await this.props.confirmParcel(merchant_mobile_number,order_id,recipient_mobile_number,shop_id)
        if(res === 201){
            this.getData()
        }
    }
  render() { 
    
    const columns = [
      {
        title: 'Order ID',
        dataIndex: 'key',
        key: 'key',
        width: '15%',
        ...this.getColumnSearchProps('key'),
      },
      {
        title: 'Store Info',
        dataIndex: 'store_name',
        key: 'store_name',
        width: '15%',
        expandRowByClick: true,
        ...this.getColumnSearchProps('store_name'),
      },
      {
        title: 'Place',
        dataIndex: 'to',
        key: 'to',
        width: '10%',
      },
      {
        title: 'City',
        dataIndex: 'city',
        key: 'city',
        width: '10%',
        ...this.getColumnSearchProps('city'),
      },
      {
        title: 'Area',
        dataIndex: 'area',
        key: 'area',
        width: '10%',
        ...this.getColumnSearchProps('area'),
      },
      {
        title: 'Customer Info',
        dataIndex: 'customer_info',
        key: 'customer_info',
        width: '20%',
        ...this.getColumnSearchProps('customer_info'),
      },
      {
        title: 'Amount to collect',
        dataIndex: 'charge_info',
        key: 'charge_info',
        width: '10%',
      },
      {
        title: 'Order Time',
        dataIndex: 'order_time',
        key: 'order_time',
        width: '15%',
        ...this.getColumnSearchProps('order_time'),
      },
      
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        width: '10%'
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        width: '15%'
      }
    ];
    const {data} = this.state
    const rows = [...
      data
        ? data?.map((item,id) => this.createData(
            item.order_id
            , 
              item?.to === 'in_city' ?
                item?.shop?.name + '(' + item?.shop?.mobile_number +')'
              :
              'Bahon'
            ,
            <Row gutter={12}>
              <Col xs={12}>
                <h3>Shop</h3>
                {item?.to === 'in_city' ?
                <>
                  <p style={{margin: '2px 0px'}}>{item?.shop?.shop_id}</p>
                  <p style={{margin: '2px 0px'}}>{item?.shop?.name}</p>
                  <p style={{margin: '2px 0px'}}>{item?.shop?.mobxile_number}</p>
                  <p style={{margin: '2px 0px'}}>{item?.shop?.address}</p>
                </> 
                :
                
                  <p style={{margin: '2px 0px'}}>Bahon</p>
                }
                
              </Col>
              <Col xs={12}>
                <h3>Customer</h3>
                <p style={{margin: '2px 0px'}}>{item?.recipient_name}</p>
                <p style={{margin: '2px 0px'}}>{item?.recipient_mobile_number}</p>
                <p style={{margin: '2px 0px'}}>{item?.recipient_address}</p>
              </Col>
               {/* + ' Shop Number: ' + item?.shop?.mobile_number + ' Shop Address ' + item?.shop?.address */}
            </Row>,
              item?.to === 'in_city' ? 
                <Tag color="blue">{item?.to}</Tag>
              :
                <Tag color="purple">{item?.to}</Tag>
            ,
              this.state?.coverageArea?.city[item?.recipient_area_code.split('-')[0]-1]?.name
            ,
              this.state?.coverageArea?.city[item?.recipient_area_code.split('-')[0]-1]?.sub_city[item?.recipient_area_code.split('-')[1]-1]?.thana[item?.recipient_area_code.split('-')[2]-1]?.area[item?.recipient_area_code.split('-')[3]-1]?.name
            ,
              item?.recipient_name + '(' + item?.recipient_mobile_number + ')'
            ,
              item?.charges?.amount_to_collect
            ,
              this.makeLocalTime(item?.created_at)
            , 
              item?.type
            ,
              <Button size='middle' type="primary" icon={<CheckOutlined />} onClick={() => this.handleSubmit(item?.merchant?.mobile_number,item.order_id,item?.recipient_mobile_number,item?.shop?.shop_id)} >Make Confirm</Button>
          ))
        : "",
    ];
    const handleCount = (a,b,c,d) => {
      if(d.currentDataSource.length > 0){
        this.setState({found: d?.currentDataSource?.length})
      }
      else{
        this.setState({found: 0})
      }
    }
    
    return (
      <>
        
        <Header title="Pending Parcels" subtitle="Parcel" name="normal_order" />
        {
          this.state.found > 0 ?
          <p style={{textAlign: 'right', padding: '10px'}}> <Tag color="blue">{this.state.found} data found</Tag>  </p>
        : 
          <p style={{textAlign: 'right', padding: '10px'}}> <Tag color="red">No data found</Tag>  </p>
        }
            {rows?.length > 0
            ? 
                <Table columns={columns} dataSource={rows} scroll={{ x: 1000 }} sticky
                rowClassName={(record) => record?.type == 'NO' ? 'table-row-light' :  'red'}
                rowKey={(record) => record?.key}
                expandable={{
                  expandedRowRender: record => <p style={{ margin: 0 }}>{record.store_info}</p>,
                  expandRowByClick: true,
                  
                }}
                onChange={(pagination, filters, sorter, extra) => handleCount(pagination, filters, sorter, extra)}
                
              />
            : 
            <Empty />}
      </>
    );
  }
}

export default connect(null,{fetchPending,confirmParcel})(PendingParcels);