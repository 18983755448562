import React, { Component } from 'react'

import { Button, Empty, Input, Space, Table, Tag } from 'antd';
import { connect } from 'react-redux';
import {fetchConfirmed} from '../../../../action/parcel'
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from "react-highlight-words";
import Header from '../../../../components/header';
class ConfirmedParcels extends Component {
  state = { 
    
    searchText: '',
    searchedColumn: '',
    data: [],
   } 
    componentDidMount = () => {
        this.getData()
    };
    getData = async () => {
        const res = await this.props.fetchConfirmed()
        this.setState({data: res})
    }
   
  
    getColumnSearchProps =  dataIndex => ({
        filterDropdown:  ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
            <Input
            ref={node => {
                this.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => {setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
                this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
                });}}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
            />
            {/* <Space>
            <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
            >
                Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
            </Button>
            <Button
                type="link"
                size="small"
                onClick={() => {
                confirm({ closeDropdown: false });
                this.setState({
                    searchText: selectedKeys[0],
                    searchedColumn: dataIndex,
                });
                }}
            >
                Filter
            </Button>
            </Space> */}
        </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
        record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
        if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
        }
        },
        render: text =>
        this.state.searchedColumn === dataIndex ? (
            <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
    });
    
    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
        searchText: selectedKeys[0],
        searchedColumn: dataIndex,
        });
    };

    createData = (order_id,store_info,to,customer_info,charge_info,type,order_time) => {
        return {order_id,store_info,to,customer_info,charge_info, type,order_time};
    };

    makeLocalTime = (date) => {
        let mainDate = date.split('T')[0];
        let hours = date.split('T')[1].split(':')[0];
        let minutes = date.split('T')[1].split(':')[1];
        var am = true;
        if (hours > 12) {
            am = false;
            hours -= 12
        } else if (hours == 12) {
            am = false;
        } else if (hours == 0) {
            hours = 12
        }
        return mainDate + " , " + hours+":"+minutes + " " + (am ? "a.m" : "p.m")
    }

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };


  render() { 
    
    const columns = [
      {
        title: 'Order ID',
        dataIndex: 'order_id',
        key: 'order_id',
        width: '15%',
        ...this.getColumnSearchProps('order_id'),
      },
      {
        title: 'Store Info',
        dataIndex: 'store_info',
        key: 'store_info',
        width: '20%',
        ...this.getColumnSearchProps('store_info'),
      },
      {
        title: 'To',
        dataIndex: 'to',
        key: 'to',
        width: '20%'
      },
      {
        title: 'Customer Info',
        dataIndex: 'customer_info',
        key: 'customer_info',
        width: '20%',
        ...this.getColumnSearchProps('customer_info'),
      },
      {
        title: 'Amount to collect',
        dataIndex: 'charge_info',
        key: 'charge_info',
        width: '10%',
      },
      {
        title: 'Order Time',
        dataIndex: 'order_time',
        key: 'order_time',
        width: '12%',
        ...this.getColumnSearchProps('order_time'),
      }
    ];
    const {data} = this.state
    const rows = [...
      data
        ? data?.map((item,id) => this.createData(
            item.order_id
            , 
            item?.shop?.shop_id + '--' + item?.shop?.name + '--' + item?.shop?.mobile_number + ' -- ' + item?.shop?.address
            ,
            item?.to === 'in_city' ? 
              <Tag color="blue">{item?.to}</Tag>
            :
              <Tag color="purple">{item?.to}</Tag>
            ,
              item?.recipient_name + '--' + item?.recipient_mobile_number + '--' + item?.recipient_address
            ,
                item?.charges?.amount_to_collect
            ,
              item?.type
            ,
              this.makeLocalTime(item?.created_at)
            , 
           
            
          ))
        : "",
    ];
    
    return (
      <>
        
        <Header title="Confirmed Parcels" subtitle="Parcel"  name="normal_order" />
            {rows?.length > 0
            ? 
                <Table columns={columns} dataSource={rows} scroll={{ x: 1000 }} sticky
                
                rowClassName={(record) => record?.type == 'NO' ? 'table-row-light' :  'red'}
                  />
            : 
            <Empty />}
      </>
    );
  }
}

export default connect(null,{fetchConfirmed})(ConfirmedParcels);